@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css"

/* You can add global styles to this file, and also import other style files */
@font-face
  font-family: 'Cinzel'
  src: url('/assets/fonts/Cinzel-Regular.ttf') format('truetype')

@font-face
  font-family: 'LibreBaskerville'
  src: url('/assets/fonts/LibreBaskerville-Regular.ttf') format('truetype')

*
  font-family: LibreBaskerville

body
  margin: 0
  background-color: #EFEFEF

h1,h2,h3,h4,h5, span
  font-family: Cinzel

mat-toolbar a
  font-family: Cinzel


.mat-list-actions
  position: absolute
  right: 12px
  bottom: 4px
  .edit-item-button mat-icon
      color: white



h1.section-title
  font-size: 64px
  line-height: 64px
  text-align: center
  margin-bottom: 3px
  width: 100%
  font-family: Cinzel


h2.section-subtitle
  font-size: 32px
  font-weight: normal
  text-align: center
  width: 100%
  font-family: Cinzel

.container
  width: 85%
  margin: 16px auto 32px auto
  display: flex
  justify-content: space-between
  flex-flow: row
  flex-wrap: wrap
  mat-card
    .edit
      position: absolute
      top: 4px
      right: 4px
      &:hover
        cursor: pointer
    width: 85%
    text-align: center
    min-height: 50%
    h4
      text-align: center
      margin-bottom: 32px
      padding-top: 25px
    h1,h2,h3,h4,h5
      font-family: Cinzel
      text-align: center
    strong
      font-size: 28px
      font-family: Cinzel
    mat-card-content
      text-align: left
    .editing
      mat-list-item
        min-height: 65px
        margin: 0
        padding: 0

mat-dialog-content
  width: 50vw
  text-align: center
  mat-form-field
    width: 60%
    margin: 0 auto

.unequip-icon
  padding: 0 10px
  vertical-align: middle

.version
  position: absolute
  bottom: 0
  margin-top: 10px
  left: 12em
  text-align: center
  font-size: 8pt
